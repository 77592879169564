<template>
  <div
    class="item-dialog-edit"
    :class="[
      `item-dialog-edit-${itemType}`,
      itemType === 'quote' ? 'pb-0' : 'pb-5'
    ]"
  >
    <template
      v-if="itemType === 'note'"
    >
      <div
        class="item-dialog-edit__quill"
      >
        <quill-toolbar 
          id="quill-toolbar"
          :disabled="disabledToolbar"
          @mounted="toolbarMounted = true"
          :editor-ready="editorReady"
          :local-history="localHistory"
        />
        <template
          v-if="toolbarMounted"
        >
          <text-item-title
            v-model="item.info.title"
          />
          <quill-editor
            class="flex-grow-1"
            ref="myQuillEditor"
            v-model="item.content.text"
            :options="editorOptions"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
          />
        </template>
      </div>
    </template>
    <template
      v-else-if="itemType === 'image'"
    >
      <v-sheet
        height="100%"
        color="transparent"
      >
        <div
          style="height: 100%;"
          class="item-dialog-edit-dropzone d-flex justify-center align-center"
          @click="onClickDropZone"
          @dragover.stop.prevent="onDragover"
          @drop.stop.prevent="onDrop"
          v-if="!tempFiles.length"
        >
          <input
            style="position: absolute; left: 0px; top: 0px; visibility: hidden; opacity: 0px; left: -10000px; top: -10000px;"
            ref="files" 
            type="file" 
            @change="onChangeFileInput" 
            accept="image/*"
            multiple
          />
          <div
            class="item-dialog-edit-dropzone__title text-center title"
          >
            Click and choose <br />
            or drag & drop a files
          </div>
        </div>
        <v-card
          v-else
          height="100%"
          :loading="inReadFilesProcess"
        >
          <image-preview
            :hide-list="tempFilesUrls.length <= 1"
            :inline="tempFilesUrls.length <= 1"
            :images="tempFilesUrls"
          />
        </v-card>
      </v-sheet>
    </template>
    <template
      v-else-if="itemType === 'quote'"
    >
      <component 
        v-bind:is="`quote-edit`"
        :item="item"
      />
    </template>
    <template 
      v-else
    >
      Edit
    </template>
  </div>
</template>
<script>
const QuillToolbar = () => import('@/components/bars/quill')
const ImagePreview = () => import('../../views/image')
const TextItemTitle = () => import('../../textItemTitle')

const QuoteEdit = () => import('./quote')


import Quill from 'quill'
var icons = Quill.import("ui/icons")
icons["undo"] = '<svg viewBox="0 0 24 24" id="ghq-svg-undo"><path d="M12.5 3a8.99 8.99 0 00-7.796 4.517l-.298-1.715a1.005 1.005 0 00-.62-.779.918.918 0 00-.945.18c-.263.236-.387.602-.326.96l.71 4.015a.647.647 0 00.065.19c.009.056.025.11.048.16.039.059.083.113.132.162l.076.1a.726.726 0 00.189.11c.028.026.06.047.094.06a.836.836 0 00.322.06h.151l4.018-.712a.972.972 0 00.743-.64c.122-.34.065-.723-.151-1.004a.93.93 0 00-.894-.363l-1.56.27-.04-.018A6.993 6.993 0 0112.5 5.007c3.866 0 7 3.146 7 7.026a7.021 7.021 0 01-5.62 6.888 5.186 5.186 0 01-.42.064c-.547.062-.96.526-.96 1.077 0 .518.42.938.938.938l.09-.005.355-.04c.108-.014.204-.03.29-.045 4.17-.787 7.327-4.462 7.327-8.877C21.5 7.044 17.47 3 12.5 3z"></path></svg>'
icons["redo"] = '<svg viewBox="0 0 24 24" id="ghq-svg-redo"><path d="M11.5 3a8.99 8.99 0 017.796 4.517l.298-1.715c.06-.359.297-.656.62-.779a.918.918 0 01.945.18c.263.236.387.602.326.96l-.71 4.015a.647.647 0 01-.065.19.608.608 0 01-.048.16 1.057 1.057 0 01-.132.162l-.076.1a.726.726 0 01-.189.11.309.309 0 01-.094.06.836.836 0 01-.322.06h-.151l-4.018-.712a.972.972 0 01-.743-.64 1.067 1.067 0 01.151-1.004.93.93 0 01.894-.363l1.56.27.04-.018A6.993 6.993 0 0011.5 5.007c-3.866 0-7 3.146-7 7.026a7.021 7.021 0 005.62 6.888c.115.024.255.045.42.064.547.062.96.526.96 1.077 0 .518-.42.938-.938.938l-.09-.005-.355-.04a5.465 5.465 0 01-.29-.045c-4.17-.787-7.327-4.462-7.327-8.877C2.5 7.044 6.53 3 11.5 3z"></path></svg>'

export default {
  props: {
    dark: {
      type: Boolean
    },
    item: {
      type: Object,
      required: true
    }
  },
  name: 'EditItemDialog',
  components: {
    ImagePreview,
    TextItemTitle,
    QuillToolbar,
    QuoteEdit
  },
  data: () => ({
    localHistory: {
      undo: [],
      redo: []
    },
    showMoveable: false,
    editorReady: false,
    toolbarMounted: false,
    disabledToolbar: false,
    inReadFilesProcess: false,
    currentFocusIndex: 0,
    tempFiles: []
  }),
  mounted() {
    const quillEditor = this.$refs['myQuillEditor']
    if (quillEditor) {
      const { $el } = quillEditor
      const editor = $el.querySelector('.ql-container .ql-editor')
      if (editor) {
        // editor.classList.add('preview-container')
        editor.classList.remove('ql-editor')
        editor.focus();
      }
    }

    this.$nextTick(() => {
      this.showMoveable = true
    })
  },
  computed: {
    history() {
      return this.editor.history
    },
    editorOptions() {
      return {
        theme: 'snow',
        modules: {
          toolbar: {
            history: {
              delay: 1000,
              maxStack: 100,
              userOnly: false
            },
            container: '#quill-toolbar',
            handlers: {
              'undo' : this.myUndo,
              'redo' : this.myRedo,
            }
          },
        }
      } 
    },
    tempFilesUrls() {
      return this.tempFiles.filter(([, url]) => url).map(([, url]) => url)
    },
    itemType() {
      return this.item.type
    },
    disabledUndo() {
      return true
    },
    disabledRedo() {
      return true
    },
    editor() {
      const { myQuillEditor } = this.$refs
      const { quill } = myQuillEditor

      return quill
    },
  },
  methods: {
    myUndo() {   
      this.editor.history.undo()

      const { stack } = this.history
      this.localHistory = stack 
    },
    myRedo() {
      this.editor.history.redo()

      const { stack } = this.history
      this.localHistory = stack 
    },
    onDragover(e) {
      e.dataTransfer.dropEffect = 'copy'
    },
    onDrop(e) {
      const fileList = e.dataTransfer.files || []
      this.workWithFiles(fileList)
    },
    readImage(file) {
      return new Promise((res) => {
        if (file.type && !file.type.startsWith('image/')) {
          console.log('File is not an image.', file.type, file)
          res(null)
        }

        const reader = new FileReader()
        reader.addEventListener('load', (event) => {
          res(event.target.result)
        })
        reader.readAsDataURL(file)
      })
    },
    readFiles(files) {
      return Promise.all(Array.from(files).map(this.readImage))
    },
    async workWithFiles(files) {
      this.inReadFilesProcess = true
      const filesData = await this.readFiles(files)
      this.tempFiles = Array.from(files).map((file, index) => [file, filesData[index]])
      this.inReadFilesProcess = false
    },
    onChangeFileInput({ target }) {
      const { files = []} = target
      this.workWithFiles(files)
    },
    onClickDropZone() {
      const { files: filesRef } = this.$refs
      filesRef && filesRef.click()
    },
    onEditorBlur() {
      console.log('Blur')
    },
    onEditorFocus() {
      console.log('Focus')
    },
    onEditorReady(e) {
      this.editorReady = true
      e.history.clear()
    }
}
}
</script>
<style lang="scss">
.item-dialog-edit {
  height: 100%;

  &-quote {
    margin: -5vh -32px 0px;
    height: calc(100% + 5vh);
    padding-bottom: 0px !important;
  }

  &__quill {
    height: 100%;
    display: flex;
    max-width: 734px;
    margin: 0 auto;
    width: 100%;
    flex-flow: column nowrap;
    padding-top: 70px;

    .quill-editor {
      padding-bottom: 20vh;
    }
  }

  .ql-container.ql-snow {
    font-size: inherit;
    width: 100%;
    margin: auto;
    border: none;
    font-family: 'Lora' !important;

    & > div {
      height: 100%;
      outline: none;
    }

    .ql-editor {
      margin: auto;
      text-align: left !important;
      padding: 0px;
    }
    .ql-editor.ql-blank::before {
      left: 3px;
      font-style: normal;
      content: "Type away!"
    }
  }

  .ql-toolbar.ql-snow {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background: #fff;
    z-index: 2;
    border-color: #ddd;
  }
}

.theme--dark .ql-toolbar.ql-snow {
  background: #000;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.12);
  
  .ql-stroke, .ql-fill  {
    stroke: #fff !important;
  }

  .ql-picker-options {
    background-color: #000;
    color: #fff;
  }

  .ql-picker-label {
    color: #fff;
  }
}
</style>